import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageUploadService } from 'src/app/services/base/uploads/images/image-upload.service';
import { MessagesService } from 'src/app/services/base/messages/messages.service';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { ApiService } from 'src/app/services/base/api/api.service';

@Component({
  selector: 'gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.scss']
})
export class GalleryListComponent implements OnInit,OnChanges {

  @Input() nome: any = "";
  @Input() id: any = [];
  @Input() idioma: any = "pt";
  @Input() uploadPath: string;
  
  items: any; 
  itemsAux: any;
  selectedItems: any = [];
  parentPage: string;
  checkedSelectAll: boolean;
  countUpload = 0;
  toUpload: boolean;
  opcao1: number;

  constructor(
    private modalService: NgbModal,
    private messages: MessagesService,
    private cdf: ChangeDetectorRef,
    private service: ImageUploadService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private api: ApiService
  ) {
    this.parentPage = this.router.url.split("/")[1];
  }

  async ngOnInit(): Promise<void> {
    this.items = await this.getAll();
    this.items?.map(a => {
      a.visivel = a.visivel == 1;
      a.url = this.uploadPath + a.url
    });
    this.items = this.items.sort((first, next) => first.ordem - next.ordem);
    this.itemsAux = [...this.items];
    this.cdf.detectChanges();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['idioma']) {
      this.items = await this.getAll();
      this.items?.map(a => {
        a.visivel = a.visivel == 1;
        a.url = this.uploadPath + a.url
      });
      this.items = this.items.sort((first, next) => first.ordem - next.ordem);
      this.itemsAux = [...this.items];
      this.cdf.detectChanges();
    }
  }

  getAll() {
    let response = this.service.getAll(this.parentPage,this.idioma,this.id);
    return response;
  }

  async upload(){
      this.messages.swalLoading();
    
      let responseUpload = await this.uploadImagem(this.items, this.parentPage);
      if (!responseUpload) {
        this.messages.swalError();
        return;
      }

      let body ={
        opcao : this.opcao1,
        id : this.id,
        data: responseUpload
      };

      let response = await this.service.insert(this.parentPage,this.idioma, body);
      if (response.message == "success") {
        response.data.forEach(element => {
          element.map(a => {a.url = this.uploadPath + a.url });
          this.items = this.items.filter(a => !a.toUpload).concat(element);
          this.itemsAux = [...this.items];
        });
        
        this.countUpload = 0;
        this.messages.toastSuccess();
        this.cdf.detectChanges();
      }
      else {
        this.messages.swalError();
      }
  }

  async deleteSelected() {
    let result = await this.messages.swalConfirmDeleteAll();

    if (result.isDismissed) return;

    if (result.value) {
      this.messages.swalLoading();
      let ids = this.selectedItems.map(a => a.id).join();
      let response = await this.delete(ids);
      if (response) {

        this.items = this.itemsAux.filter(a => !ids.split(',').includes(a.id));
        this.itemsAux = [...this.items];

        this.selectedItems = [];
        this.checkedSelectAll = false;

        this.cdf.detectChanges();
        this.messages.toastSuccess();

        return;
      }
    }
    this.messages.swalError();
  }

  selectItem(e: any, item: any) {
    if (e.target.checked) {
      this.selectedItems.push(item);
      item.checked = true;
    } else {
      this.selectedItems = this.selectedItems.filter(a => a.id != item.id)
      item.checked = false;
    }
  }

  selectAll(e: any) {
    this.items.map(a => a.checked = e.target.checked);
    if (e.target.checked) {
      this.selectedItems = this.items;
      return;
    }
    this.selectedItems = [];
  }

  async deleteChange(id: number): Promise<void> {
    let result = await this.messages.swalConfirmDelete();
    if (result.value) {
      this.messages.swalLoading();
      let response = await this.delete(id);
      if (response) {
        this.messages.toastSuccess();
        //Atualizar pelo javascript
        this.items = this.items.filter(a => a.id !== id);
        this.itemsAux = [...this.items];

        this.selectedItems = this.selectedItems.filter(a => a.id != id);

        this.cdf.detectChanges();
      }
      else {
        this.messages.swalError();
      }
    }
  }
  async switchChange(e: any, item: any, field: string = 'visivel') {
    this.messages.toastLoading();
    let data = {}
    data[field] = e.srcElement.checked;
    let response = await this.updateData(item.id, data);

    if (response.message == "success") {
      this.messages.toastSuccess();
      this.itemsAux = [...this.items];
      return;
    }
    data[field] = !e.srcElement.checked;
    this.cdf.detectChanges();
    this.messages.toastError();
  }
  async changeOrder(event: any, item: any) {
    if (item.changed) {
      this.messages.toastLoading();
      this.items = this.items.sort((first, next) => first.ordem - next.ordem);
      let data = {
        ordem: event.srcElement.value
      }
      let response = await this.updateData(item.id, data);
      if (response.message == "success") {
        this.messages.toastSuccess();
        return;
      }
    }
  }

  async updateData(id: number, data: any) {
    let response = await this.service.update(this.parentPage,this.idioma,id,data);
    return response;
  }

  async delete(id: number) {
    let response = await this.service.delete(this.parentPage,this.idioma, id);
    return response.message !== "error";
  }

  deleteImageBeforeUpload(item: any) {
    this.items = this.items.filter(a => a != item);
    this.countUpload--;
  }

  openFileSelect() {
    document.getElementById("imageSelect").click();
  }

  addImageToList($event) {
    let files = $event.target.files;
    let image: any;
    let file: any;
    Object.entries(files).forEach((k, v) => {
      file = k[1];
      let url = URL.createObjectURL(file);
      image = {
        url: this.sanitizer.bypassSecurityTrustUrl(url),
        nome: file['name'],
        ordem: 99,
        toUpload: 1,
        file: file
      }
      this.items.push(image);
    });
    if(!this.opcao1){
      this.opcao1= 1;
    }
     
    this.messages.swalClose();
    this.toUpload = true;
    this.countUpload++;
  }

  async uploadImagem(fileItems: any, url: string) {
    let response: any = {};
    let toInsert = fileItems.filter(a => !a.id);

    if (toInsert.length > 0) {
      let files = toInsert.map(a => a.file);
      response = await this.api.upload(files, url);

      if (!response) {
        this.messages.swalError("Upload Error");
        return;
      }
    }
    return response?.data;
  }

}
